import Swal from "sweetalert2";

function get_localstorage_user_data(key = "") {
  let userInfo = localStorage.getItem(key);
  if (userInfo) {
    return JSON.parse(userInfo);
  } else {
    return {};
  }
}

function set_localstorage_user_data(userInfo = {}) {
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
}

function remove_user_data_from_localstorage(key = "") {
  localStorage.removeItem(key);
}

function empty_checker(data = "") {
  let length = data_length(data);
  return length === 0;
}

function data_length(data) {
  return data.length;
}

function pop_up(msg, icon, timer = 1500, toast = true, position = "top-end") {
  Swal.fire({
    position: position,
    icon: icon,
    title: msg,
    showConfirmButton: false,
    timer: timer,
    toast: toast
  });
}

export default {
  get_localstorage_user_data,
  set_localstorage_user_data,
  remove_user_data_from_localstorage,
  pop_up,
  empty_checker
};
