$.noConflict();

function showModal(id) {
  jQuery("#" + id).fadeIn(200);
  jQuery("body").toggleClass("no-scroll");
}

function hideModal(id) {
  jQuery("#" + id).fadeOut(400);
  jQuery("body").toggleClass("no-scroll");
}

function openNav(id = "mySidenav") {
  document.getElementById(id).style.width = "100vw";
}

function closeNav(id1 = "mySidenav") {
  document.getElementById(id1).style.width = "0";
  //document.getElementsByClassName(id2).style.width = "0";
}

function msisdnLength(msisdn, minLength, maxLength) {
  return !(msisdn.length >= minLength && msisdn.length <= maxLength);
}

export default {
  showModal,
  hideModal,
  openNav,
  closeNav,
  msisdnLength
};
