<template>
  <div class="container-main" v-if="$root.homeApiCall">
    <div class="content-slider-large" v-if="$root.banners.length">
      <div
        class="large-slider"
        v-for="banner in $root.banners"
        :key="banner.id"
      >
        <img :src="banner.thumbnail_path" />
      </div>
    </div>
  </div>
</template>

<script>
import slider from "@/js/slider";

export default {
  name: "Banner",
  created() {
    this.slider();
  },
  mounted() {
    this.slider();
  },
  updated() {
    this.slider();
  },
  methods: {
    slider() {
      var $jq = jQuery.noConflict();
      let sliderConfig = slider.largeSlider;
      $jq(".content-slider-large")
        .not(".slick-initialized")
        .slick(sliderConfig);
    }
  }
};
</script>

<style scoped></style>
