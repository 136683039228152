<template>
  <div class="container-main">
    <div class="header">
      <div class="head-nav">
        <div class="menu-icons">
          <div class="menu-bar" @click="openNav('mySidenav')">
            <span> </span>
            <span> </span>
            <span> </span>
          </div>
          <div class="mobile-menu-main" id="mySidenav">
            <div class="mob-menu-items">
              <div class="close-btn" @click="closeNav('mySidenav', '*')"></div>
              <ul>
                <li>
                  <a class="discard" @click="closeNav('mySidenav', '/')">হোম</a>
                </li>
                <li>
                  <a class="discard" @click="closeNav('mySidenav', '/enrolled')"
                    >আমার টুর্নামেন্ট</a
                  >
                </li>
                <li>
                  <a class="discard" @click="closeNav('mySidenav', '/faq')"
                    >হুল্লোড় সম্পর্কে</a
                  >
                </li>
                <li>
                  <a
                    class="discard"
                    @click="closeNav('mySidenav', '/terms-conditions')"
                    >শর্ত ও নিয়মাবলী</a
                  >
                </li>
                <li>
                  <a
                    class="discard"
                    @click="closeNav('mySidenav', '/privacy-policy')"
                    >প্রাইভেসি পলিসি</a
                  >
                </li>
                <li>
                  <a
                    class="discard"
                    @click="closeNav('mySidenav', '/contact-us')"
                    >যোগাযোগ</a
                  >
                </li>
                <li v-if="msisdn_checker()">
                  <a class="discard" @click="logout('mySidenav')">লগ আউট </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <router-link class="logo-area" tag="div" to="/"
          ><img :src="$root.logo.thumbnail_path"
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import popup from "@/js/popup";
import helper from "@/api/helper";
export default {
  name: "Header",
  methods: {
    openNav(id) {
      popup.openNav(id);
    },
    closeNav(id1, path = "") {
      popup.closeNav(id1);
      if (this.$route.path !== path && path !== "*") {
        this.$router.push(path);
      }
    },
    logout(id1) {
      popup.closeNav(id1);
      this.$root.remove_user_data_from_localstorage("userInfo");
      window.location.href = "/";
    },
    msisdn_checker() {
      let userLocalStorageData = this.$root.get_localstorage_user_data(
        "userInfo"
      );
      let { msisdn } = userLocalStorageData;
      console.log("msisdn check start", msisdn);
      let status = helper.empty_checker(msisdn);
      console.log("status ", status);
      return !status;
    }
  }
};
</script>

<style scoped></style>
