import Vue from "vue";
import VueRouter from "vue-router";
import Banner from "../views/Banner.vue";
import Header from "../views/Header.vue";
import BottomNav from "../views/BottomNav.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      default: () =>
        import(/* webpackChunkName: "Home" */ "../views/home/Home"),
      slider: () =>
        import(/* webpackChunkName: "GameSlider" */ "../views/GameSlider"),
      header: Header,
      banner: Banner,
      bottomNav: BottomNav
    },
    meta: {
      title: "Enjoy instant games, Play tournaments & win prizes! | Hullor"
    }
  },
  {
    path: "/enrolled",
    name: "Enrolled",
    components: {
      default: () =>
        import(/* webpackChunkName: "Enrolled" */ "../views/enrolled/Enrolled"),
      //slider: () => import(/* webpackChunkName: "GameSlider" */ "../views/GameSlider"),
      header: Header,
      // banner: Banner,
      bottomNav: BottomNav
    },
    meta: {
      title: "Enrolled | Hullor"
    }
  },
  {
    path: "/leaderboard/:tournamentId",
    name: "Leaderboard",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "Leaderboard" */ "../views/leaderboard/Leaderboard"
        ),
      // header: Header,
      bottomNav: BottomNav
    },
    meta: {
      title: "Leaderboard | Hullor"
    }
  },
  {
    path: "/faq",
    name: "Faq",
    components: {
      default: () => import(/* webpackChunkName: "Faq" */ "../views/Faq.vue"),
      header: Header
    },
    meta: {
      title: "FAQ | Hullor"
    }
  },
  {
    path: "/contact-us",
    name: "Contact",
    components: {
      default: () =>
        import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
      header: Header,
      bottomNav: BottomNav
    },
    meta: {
      title: "Contact | Hullor"
    }
  },
  {
    path: "/terms-conditions",
    name: "TermCondition",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "TermCondition" */ "../views/TermCondition.vue"
        ),
      header: Header
    },
    meta: {
      title: "TermCondition | Hullor"
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "PrivacyPolicy" */ "../views/PrivacyPolicy.vue"
        ),
      header: Header
    },
    meta: {
      title: "PrivacyPolicy | Hullor"
    }
  },
  {
    path: "/single-game/:gameId/play",
    name: "GamePlay",
    components: {
      default: () =>
        import(/* webpackChunkName: "GamePlay" */ "../views/GamePlay.vue")
    },
    meta: {
      title: "Game Play | Hullor"
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
