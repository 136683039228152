<template>
  <div id="app" :class="$root.mainWrapper">
    <router-view name="header" />
    <router-view name="banner" />
    <router-view name="slider" />
    <router-view :key="$route.fullPath" />
    <router-view name="bottomNav" />
  </div>
</template>
<script>
export default {
  name: "App"
};
</script>

<style></style>
