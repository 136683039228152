let sliderConfig = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  infinite: true,
  arrows: false,
  cssEase: "linear",
  autoplay: true,
  speed: 800
};
let largeSlider = {
  //slidesToShow: 2,
  slidesToShow: 2,
  slidesToScroll: 1,
  dots: false,
  infinite: true,
  arrows: false,
  cssEase: "linear",
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
export default {
  sliderConfig,
  largeSlider
};
