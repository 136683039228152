import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import storageApi from "@/api/helper";
import VueAnalytics from "vue-analytics";
// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: "UA-155148996-6",
  router
});

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  let stateName = ["GamePlay"];
  if (stateName.indexOf(to.name) !== -1) {
    document.body.classList.add("bg-none");
  } else {
    document.body.classList.remove("bg-none");
  }
  next();
});
new Vue({
  data: {
    mainWrapper: "wrapper-main",
    banners: [],
    rewards: [],
    rules: [],
    translation: {},
    containImage: {},
    gameRuleImage: {},
    tounamentHeaderImage: {},
    subscribedGameHeaderImage: {},
    testimonailImage: {},
    LEADERBOARD: {},
    logo: {},
    footer_logo: {},
    homeApiCall: false
  },
  methods: {
    bannerImage(images = [], key = null, multipleData = true) {
      if (multipleData) {
        this[key] = images;
      } else {
        this[key] = images[0];
      }
    },
    url() {
      return process.env.VUE_APP_API_URL;
    },
    set_localstorage_user_data(userInfo = {}) {
      storageApi.set_localstorage_user_data(userInfo);
    },
    get_localstorage_user_data(key = "") {
      return storageApi.get_localstorage_user_data(key);
    },
    remove_user_data_from_localstorage(key = "") {
      storageApi.remove_user_data_from_localstorage(key);
    }
  },
  watch: {
    $route(to) {
      let toState = ["GamePlay"].includes(to.name);
      if (toState) {
        this.$root.mainWrapper = "main-frame";
      } else {
        this.$root.mainWrapper = "wrapper-main";
      }
    }
  },
  router,
  store,
  render: h => h(App)
}).$mount("#app");
