<template>
  <div class="container-main" v-if="$root.homeApiCall">
    <footer class="main-footer">
      <div class="footer-btm">
        <div class="container">
          <div class="footer-logo">
            <img :src="$root.footer_logo.thumbnail_path" />
          </div>
          <h3>
            {{ $root.translation.footer_title }}
          </h3>
          <p>
            {{
              `${$root.translation.footer_title2} ${new Date().getFullYear()}`
            }}
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "BottomNav"
};
</script>

<style scoped></style>
